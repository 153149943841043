<template>
<div class="msp-gare">
  <msp-app-bar></msp-app-bar>
<v-sheet
    tile
    color="grey lighten-3"
    class="gare-nav d-flex"
:class="classFiltro"
    >


      <v-card class="gare-filtri-attivi mb-1" v-if="filtriCollapsed">
      <v-card-title>Filtri attivi:
     <v-btn
icon
@click="toggleFiltri"
>
<span >
<v-icon title="nascondi filtri">mdi-chevron-down</v-icon>      
</span>
</v-btn> </v-card-title>
      <v-card-text>
          <v-chip @click="toggleFiltri" class="gara-data" v-text="selectedFilterAnno"> </v-chip>
        <v-chip @click="toggleFiltri" v-if="cerca.sport_id">
            <i class="gara-icon" :style="styleSelectedFilterSport" :class="classSelectedFilterSport" alt=""></i>
              <span class="ml-1 gara-specialita" v-text="selectedFilterSpecialita"></span>
        </v-chip>

      </v-card-text>
      </v-card>
<v-card class="gare-filtro" >
<v-app-bar
dense
flat >
<v-toolbar-title>Filtri</v-toolbar-title>
<v-spacer></v-spacer>

      <v-btn
      icon
v-if="!filtriCollapsed"
      class="ma-2"
      @click="toggleFiltri"
      >
      <span >
    <v-icon title="nascondi filtri">mdi-close</v-icon>      
      </span>
      </v-btn>
</v-app-bar>
      <v-card-text>
          
              <v-select
              :value="filterAnno"
              :items="years"
              label="Filtra per data"
              item-text="label"
              item-value="value"
              return-object
    @change="changeAnno"
              ></v-select>
    
              <v-select
              :value="cerca.sport_id"
              :items="sports"
              label="Filtra per sport"
              item-text="descrizione"
              item-value="id"
    @change="changeSport"
              ></v-select>
    
              <v-select
              v-if="cerca.sport_id"
              :value="cerca.sport_specialita_id"
              :items="specialita"
              label="Filtra per specialità"
              item-text="descrizione"
              item-value="id"
    @change="changeSpecialita"
              ></v-select>
    
      </v-card-text>
    </v-card>

<v-btn
      class="ma-2"
      @click="switchSortOrder"
      >
      <span >
    <v-icon v-if="sortAsc" title="ordinamento crescente">mdi-sort-calendar-ascending</v-icon>      
    <v-icon v-else title="ordinamento decrescente">mdi-sort-calendar-descending</v-icon>      
      </span>
      </v-btn>
    </v-sheet>
<v-sheet
class="calendar-list mx-auto"
ref="calendar-list"
>

<div class="calendar-cells">


<div class="calendar-cell"
v-for="gara in filteredGare"
    :key = "gara.id" >
<div class="day-container"
@click="clickDay({date:day.date, event:$event})" >
<h4
class="day-number"
v-html="formatCalendarDay(gara.data)" >
</h4>
</div>    
    <msp-gara-item
    :gara="gara"
    @click="openModal(gara)"
    ></msp-gara-item>
</div>
</div>

<v-dialog
v-model="showModal"
fullscreen
hide-overlay
transition="slide-x-transition"
:return-value="'detail'"
>
<router-view></router-view>
</v-dialog>
</v-sheet>
<msp-app-footer></msp-app-footer>
</div>
</template>

<script>

import moment from "moment";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import mspGaraItem from "@components/MspGaraItem.vue";
import mspAppBar from "@components/MspAppBar.vue";
import mspAppFooter from "@components/MspAppFooter.vue";
import {  mapGetters } from 'vuex'
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import _cal from "@src/js/vue/mixins/calendario.js";
import version from "@mixins/version.js"
import _funzioni from '@src/js/msp/funzioni.js';

moment.locale("it");

const component = {
  
  mixins: [
    allenamentoCalcoli,
    env,
    version,
  ],

  components: {
    mspGaraItem,
    mspAppBar,
    mspAppFooter,
  },

  destroyed: function () {
    let list = document.querySelector('.calendar-list');
    if(!list) { return; }
    removeEventListener('scroll',this.onCalendarScroll);
  },

  watch: {
      
    dialog: function (val) {
      this.showModal = val;
      },

    $route: {
      immediate: true,
      handler: function(newVal) {
        this.showModal = newVal.meta && newVal.meta.showModal;
      }
    }

  },

  mounted: function () {
      this.init();
  },

  created () {
  },

  data: () => ({
    loading: false,
    showModal: false,
    canScroll: true,
    canLoad: true,
    from: new Date(),
    to: new Date(),
    today: new Date(),
    current: false,
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    filtriCollapsed: true,
    settimane: [],
    items: [],
    groups: [],
    days: [],
    weeks: [],
    autogroups: false,
    start: new Date(),
    end: '',
  }),

  computed: {
      ...mapGetters('gare', [
      'gare',
      'cerca',
      'sports',
      'sport_specialita',
      'specialita',
      'years',
      'filterAnno',
      'sortAsc',
      ]),

      ...mapGetters('utente', [
      'utente',
      ]),

      ...mapGetters('atleti', [
      'atleta',
      ]),

      selectedFilterAnno: function () {
          if (!this.filterAnno) {
              return "";
          } else {
              return this.filterAnno.label;
          }
      },

      selectedFilterSport: function () {
          if (!this.cerca.sport_id) {
              return "";
          } else {
              return this.sports.find(el=>el.id===this.cerca.sport_id).descrizione;
          }
      },

      styleSelectedFilterSport: function () {
          if (!this.cerca.sport_id) {
              return "";
          } else {
              let sport = this.sports.find(el=>el.id===this.cerca.sport_id);
              return "--color: "+sport.colore;
          }
      },

      classSelectedFilterSport: function () {
          if (!this.cerca.sport_id) {
              return "hidden";
          } else {
              return "msp-icon-sport-"+this.cerca.sport_id;
          }

      },

      selectedFilterSpecialita: function () {
          if (!this.cerca.sport_specialita_id) {
              return "";
          } else {
              return this.specialita.find(el=>el.id===this.cerca.sport_specialita_id).descrizione;
          }

      },

      classFiltro: function () {
          return (this.filtriCollapsed) ? "collapsed" : "";
      },

      filteredGare: function () {
          //TODO aggiornare elenco dopo aver eliminato una gara
          let dir = (this.sortAsc) ? 1 : -1;
          let gare = [...this.gare];
          return gare.filter(el=>el&&el.data).sort((a,b)=>{
              return (a.data>b.data) ? dir : -1*dir;
          });
      },
  },

  methods: {
      init: function () {
          if (!this.filterAnno) {
              this.setFilterAnnoProssimeGare();
          }
          if (!(this.gare && this.gare.length)) {    
              this.loadGare();
          }
      },

      switchSortOrder: function () {
          this.$store.dispatch("gare/switchSortOrder");
      },
      toggleFiltri: function () {
          this.filtriCollapsed = !this.filtriCollapsed;
      },

      setFilterAnnoProssimeGare: function () {
          this.setFilterAnno(this.years.find(el=>el.label==="Prossime gare"));
      },

      findFirst: function (arr, container) {
          let top = container.getBoundingClientRect().top;
          for (let i = 0; i< arr.length ; i++) {
              if (arr[i].getBoundingClientRect().bottom > top) {
                  return arr[i];
              }
          }
          return arr[arr.length-1]
      },

      classGiorno: function (calendar) {
          let day = new Date(calendar);
          let classes = [];
          classes.push('day-'+day.getDate());
          if (this.isToday(calendar)) {
              classes.push('oggi');
          }
          if (this.isFirstDay(calendar)) {
              classes.push('first');
          }
          return classes.join(" ");
      },



      debounceSearch: function () {
          clearTimeout(this.MSP().storage.search);
          this.MSP().storage.search = setTimeout(
          ()=>{
              this.update();
          }, 300
          )
      },

      loadSports: function () {
          return this.sports;
      },


      setFilterAnno:  function (value) {
          let data = {value: value};
          let sortAsc;
          if (value.value === 0) {
              sortAsc = true;
          } else {
              sortAsc = false;
          }
          this.$store.dispatch("gare/setSortAsc", {sortAsc: sortAsc});
          this.$store.dispatch("gare/setFilterAnno", data);
      },

      changeAnno: function (value) {
          this.setFilterAnno(value);
          this.update();
      },
      changeSpecialita: function (id) {
          this.$store.dispatch("gare/setSportSpecialita", {id:id});
          this.update();
      },

      changeSport: function (id) {
          this.$store.dispatch("gare/setSport", {id:id});
          this.$store.dispatch("gare/updateSpecialita");
          this.update();
      },


      makeRange: function () {
          let a_params = [];
          const currentYear = this.filterAnno;
          if (!currentYear) {
              a_params = [];
          } else if (currentYear.value === 0) {
              let da  = new Date().toLocaleDateString("it");
              da = this.MSP().convertDate(da,'-');
              a_params.push("da="+da);
          } else if (currentYear.value === 1) {
              a_params = [];
          } else if (currentYear.value > 1) {
              const from = currentYear.label+"-01-01";
              const to = currentYear.label+"-12-31";
              a_params.push("da="+from);
              a_params.push("a="+to);
          }
          return a_params;
      },
      loadGare: async function () {
          if (!this.atleta) return;
          let a_params = [];
          let rangeAnni = this.makeRange();
          await this.$store.dispatch("gare/setAtletaId", this.atleta.id);
          a_params.push("calendari=gara");
          a_params = a_params.concat(rangeAnni);
          for (let k in this.cerca) {
              let val = this.cerca[k];
              if (val) {
                  a_params.push("filtro[gara."+k+"]"+"="+val);
              }
          }
          let params = a_params.join("&");
          let limits = {
              params: params,
          }
          this.loading = true;
          await this.$store.dispatch("gare/load", limits);
          this.loading = false;
      },

      openModal: function (item, mode="view") {
          if (item.data && item.data.sport_id===this.MSP().ID_RIPOSO) {
              return;
          }
          this.$store.dispatch("gare/setDialog",true);
          this.$router.push("/gare/gara/"+mode+"/"+item.id);
      },

      setFirstDay: function (day) {
          this.firstDay = day;
      },



      /* UTILITY*/



      /** Calcola la data partendo dalle coordinate (week, day) relative al giorno di partenza
       * @param {Object} calendar {day: index, week: i_w}
       * @return {Date}
       */
getDay: function () {
    let inc = 0; //DEBUG  this.countDays(calendar)
    let newDate = _cal.addDays(this.getStartDay(), inc);
    return newDate;
},

getMonth: function (calendar) {
    if (!calendar) {
        return "";
    }
    let newDate = new Date(calendar);
    let month =  moment(newDate).format("MMMM YYYY");
    return month;
},

getData: function(calendar) {
    let newDate = this.getDay(calendar);
    let formattedDate =  moment(newDate).format("YYYY-MM-DD");
    return formattedDate;
},


focusDay: async function (Ymd) {
    const calendar = this.$refs.calendar;
    // await calendar.move(Ymd);
    await calendar.focusDate(new Date(Ymd));
},


appendHistory: function (key, value) {
    let join = (location.search) ? "&" : "?";
    window.history.replaceState("scheda_allenamento", window.document.title, window.location.href+join+key+"="+value);
},

makeDays: function (from, to) {
    return _cal.makeDays(from, to);
},

getDayItems: function (date) {
    let items = this.events.filter(el=>{
        let inDate = (el.data.data===date);
        return inDate;
    }).sort((a,b)=>(a.data.posizione > b.data.posizione)? 1 :-1);
    return items;
},

update: function () {
    this.loadGare();
},

updateWeeks: function () {
    this.weeks = [];
    this.days.forEach(day=>{
        let monday = _cal.getMonday(new Date(day.date));
        let week;
        week = this.weeks.find(el=>el.monday === monday);
        if (!week) {
            week = {
                monday: monday
            }
            this.weeks.push(week);
        }
        if (!(Object.prototype.hasOwnProperty.call(week,"days"))) {
            week.days = [];
        }
        if (!week.days.find(d=>d.date===day.date)) {
            week.days.push(day);
        }
        if (!this.weeks.find(w=>w.monday===week.monday)) {
            this.weeks.push(week);
        }
    });
    this.sortWeeks();
},

sortWeeks: function () {
    this.weeks = this.weeks.sort((a,b)=>(a.monday>b.monday)?1:-1);
    this.days = this.days.sort((a,b)=>(a.date>b.date)?1:-1);
},

dayNumber: function (calendar) {
    let day = new Date(calendar);
    return +day.getDay() || 7;
},

isFirstDay: function (calendar) {
    let day = new Date(calendar);
    return (+day.getDate()===1)
},

getFormattedDay: function(day) {
    return day;
},

iconImpegno: function (day_Ymd) {
    let impegno = this.findImpegno(day_Ymd);
    if (!impegno) return "";
    let icone = {
        "1": "msp-icon-impegno-1",
        "2": "msp-icon-impegno-2",
        "3": "msp-icon-impegno-3",
        "4": "msp-icon-impegno-4",
    };
    let id = impegno.data.impegno_tipo_id;
    let icona = icone[id];
    return icona;
},

classDay: function (day_Ymd) {
    let day = new Date(day_Ymd);
    let a_classes = [];
    a_classes.push("day-"+day.getDate());
    if (this.isToday(day_Ymd)) {
        a_classes.push("oggi");
    }
    if (this.isCurrentWeek(day_Ymd)) {
        a_classes.push("current-week");
    }
    if (this.isFirstDay(day_Ymd)) {
        a_classes.push("first");
    }
    let range = this.isImpegno(day_Ymd)
    if (range) {
        a_classes.push("day-range");
        a_classes.push("day-range-"+range.type);
        a_classes.push("day-range-"+range.type+"-"+range.data.impegno_tipo_descrizione);
        a_classes.push("day-range-"+range.type+"-"+range.data.impegno_tipo_id);
    }
    return a_classes.join(" ");
},

isCurrentWeek: function (day_Ymd) {
    let monday = _cal.getMonday(day_Ymd);
    const diff = this.today - monday;
    return ((diff > 0) && (diff < (7*24*60*60*1000)));
},

isToday: function (day_Ymd) {
    let isToday = (day_Ymd === this.MSP().formatDateYmd(this.today));
    return isToday;
},

isImpegno: function (day_Ymd) {
    let day = new Date(day_Ymd);
    let impegno = this.impegni && this.impegni.find(el=>{
        let d1 = el.start;
        let d2 = el.end || d1;
        let d_in =
        ((day >= d1) && (day <=d2));
        return d_in;
    });
    return impegno;
},

addGara: function () {
    let data = {
        data: _funzioni.formatDateYmd(new Date()),
    }
    let url = this.api_base_url+"/api/v2/calendario.php?gara";
    return new Promise((resolve,reject)=>{
        _api(url,"POST", data)
        .then((result)=>{
            if (result.success) {
                resolve(result);
            } else {
                resolve(null);
            }
        })
        .catch((error)=>{
            reject(error);
        });
    });
},

getEventColor (event) {
    return event.color
},
rnd (a, b) {
    return Math.floor((b - a + 1) * Math.random()) + a
},

  }
}
export default component;
</script>

<style lang="scss">

.msp-gare {
    .calendar-cell {
        --col-gara: var(--col-grigio-chiarissimo);
        &:nth-child(odd) {
            --col-gara: #fff;
        }
    }

    .gare-filtri-attivi {
        flex: 1;

        .v-card__title {
            padding-top: 0;
            padding-bottom: 0;
            font-size: 0.8em;
            color: var(--col-grigio);
            font-weight: bold;

        }

        .v-card__text {
            padding-top: 0;
            padding-bottom: 5px;
            display: flex;
            gap: 5px;
            .gara-icon {
                font-size: 1em;
                border-radius: 5px;
            }
        }
    }
 .gare-nav {
     &.collapsed {
         overflow: hidden;
         .gare-filtro {
             display: none;
         }
     }
 }
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
    .day-impegno {
      i {
        font-family: "msp";
        font-size: 1em;
        position: relative;
      }
    }
      .day-badges {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 5px;
             i{
                 font-size: 1em;
             }

      --color: #fff;

        .day-badge {
            color: var(--color);
            line-height: 1;
            display: flex;
            flex-direction: row;
            gap: 3px;
        }
          .badge-autovalutazione {
              grid-column: 2;
          }
          .badge-peso {
              grid-column: 1;
                  --color: #000;
              }
      }
  .calendar-day {


    &.day-range-impegno {
      .day-container {
        background-color: var(--col-grigio-scuro) !important;
        position: relative;
        justify-content: space-between;
      }
      background-color: var(--col-grigio-scuro) !important;
      color: #fff;
    }

  }


  .oggi .day-container{
    background-color: var(--col-ui-evidenza) !important;
  }

  .vc-container {
    .vc-bars {
      align-self: flex-start;
    }
    .vc-focusable {
      &[tabindex="0"]
      {
        border: 2px solid var(--col-msp);

      }
    }
    width: 100%;
    .vc-dot {
      --col: var(--col-grigio-medio);
      &:before {
        content: "";
        display: none;
      }
      background: var(--col);
      &.sport-1 {
        --col: var(--col-sport-1)
      }

      &.sport-2 {
        --col: var(--col-sport-2)
      }

      &.sport-3 {
        --col: var(--col-sport-3)
      }

      &.sport-4 {
        --col: var(--col-sport-4)
      }
      &.sport-5 {
        --col: var(--col-sport-5)
      }
      &.sport-10 {
        --col: var(--col-sport-10)
      }
      &.sport-11 {
        --col: var(--col-sport-11)
      }

    }
  }
  .calendar-list {
    flex: 1;
    flex-basis: 0;
    overflow-y: scroll; /* has to be scroll, not auto */
    // -webkit-overflow-scrolling: touch;
    width: 100%;
    padding-top: 10px;
  }

  .calendar-container {
    &.compact {
      .vc-container {


        --day-min-height: 10px;
        .vc-day {
          max-height: 15px;
          overflow: hidden;
        }
      }
    }

    .v-calendar-weekly {
      height: auto;
      &__day {
        display: flex;
        flex-wrap: wrap;
      }
      &__day-label {
        flex-basis: 100%;
      }

    }
    .v-event {
      width: 10px !important;
      height: 10px !important;
      margin: 2px;
      border-radius: 50%;
      overflow: hidden;
      &>div {
        display: none;
      }
    }
    --color: #000;
    transition: all 300ms ease-in-out;
    max-height: 100vh;
  }

  .day-container {
    padding: 10px 20px;
    margin-bottom: 1px;
    background: var(--col-grigio-medio);
    color: #fff;
    display: flex;
    gap: 10px;
  }
}
.fab-label {
  position: absolute;
  right: 100%;
  transform:translateX(-10px);
}
.v-speed-dial {
  position: absolute;
}
</style>
